<template>
  <div class="newscl">
    <!-- header组件     -->
    <vheader :navId="navId"> </vheader>
    <!-- header组件     -->

    <!-- banner组件 -->
    <!-- <banner :navName="navName" :navUrl="navUrl"></banner> -->
    <div class="banner-box">
      <div class="banner-img"></div>
      <div class="banner-nav">
        <div class="banner-nav-content">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <span><i class="el-icon-location"></i>当前位置：</span>
            <el-breadcrumb-item :to="{ path: 'index' }" title="业务入口">网站首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'mediaCoverage' }" title="媒体报道">媒体报道</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'newscl1' }" title="新闻详情">新闻详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <!-- banner组件 -->
    <div class="newscl_w">
      <div class="newsContentBoxLeft">
        <h1 class="newsContentBoxLeftTitle" id="title">恒安康泰受邀参加中关村企业家交流活动</h1>
        <p class="newsContentBoxLeftTime">
          <span>发布时间：2021-09-30 18:12:22</span><span>阅读：171</span><span>来自：恒安康泰新闻中心</span><span>编辑：刘春梅</span>
        </p>
        <hr class="newsContentBoxLeftFenxian">
        <div id="frameContent">
          <p style="text-align: center;"><br></p>
          <p style="line-height: 1.75em;">
            北京市中关村海淀留学人员创业园作为中国最早的企业孵化器，容纳众多先进的高精尖技术及服务模式创新的企业，成功孵化上市企业，接纳扶持众多科技型企业发展，为入园企业提供优越政策资源条件，培植助力众多企业初创发展。</p>
          <p>为促进企业创业者的沟通交流，2021年9月专场企业管理成员生日交流会在北京中关村科技创业园一楼如期举行。恒安康泰创始人之一，贾琼总经理，受邀参加。</p>
          <p><br></p>
          <p style="text-align: center;"><img src="../../assets/img/mediaCoverage/1632996254.jpg" title="1632996254.jpg"
              alt="1632996254.jpg" width="445" height="283"></p>
          <p style="text-align: center;">贾琼总经理合照</p>
          <p><br></p>
          <p style="line-height: 1.75em;">
            作为北京中关村海淀创业园的入园企业，恒安康泰得益于创业园的多渠道资源共享及多角度国家政策扶持，在成立初期就取得大踏步的前进。立足大众医疗，促进社会民生的发展，做精、做专、做细医疗健康民生辅助服务体系。响应李克强总理及国家卫生健康委员会对开拓“互联网+医疗健康”的加速建设发展，恒安康泰正朝着全面服务大众医疗，利用互联网科技加强民生辅助不断进步，为大众健康便捷就医持续发力。
          </p>
          <p><br></p>
          <p style="text-align: center;"><img src="../../assets/img/mediaCoverage/1632996405.jpg" title="1632996405.jpg"
              alt="1632996405.jpg" width="445" height="283"></p>
          <p><br></p>
          <p><strong>恒安康泰创始人发言</strong></p>
          <p style="line-height: 1.75em;">
            恒安康泰创始人贾琼在分享会上表示：“恒安康泰很荣幸受邀参加企业创始人的生日聚会，深入参与各行各业的创业探讨，分享创业初心与经验，深入了解行业融入合作的可能性。</p>
          <p><br></p>
          <p style="line-height: 1.75em;">
            恒安康泰医疗健康是一家科技型企业，以互联网科技为支撑，发展大众医疗，推进传统医疗服务向移动医疗智能化智慧化服务的转变，突出打造人性化服务、参与性强的有温度的互联网平台。</p>
          <p><br></p>
          <p style="line-height: 1.75em;">
            综合医疗一体化平台为广大用户提供了线上问诊的极大便利。小病及常见病可以在线上平台远程问诊，不用亲自跑医院，居家环境下便捷得到三甲公立医院医师的专业医嘱及建议。在有需要面诊的情况中，我们的全程亲情医导医护服务还将进行全程预约、医疗行程安排及专业医护人员的陪同和协助。全程人性化亲情式的辅助，温馨细致的帮助每一位大众患者和普通家庭。
          </p>
          <p><br></p>
          <p style="text-align: center;"><img src="../../assets/img/mediaCoverage/1632996567.jpg" title="1632996567.jpg"
              alt="4.jpg"></p>
          <p style="text-align: center;">贾琼总经理现场分享</p>
          <p><br></p>
          <p style="line-height: 1.75em;">
            “互联网+医疗健康”是疫情背景下，国家政策大力提倡高新科技改善传统行业的风口行业，系协助医院分级诊疗，缓解各地医院接诊压力的直接辅助，也是全国各地各医疗机构迈向就医服务2.0发展的大趋势。</p>
          <p><br></p>
          <p style="line-height: 1.75em;">
            秉承打造一个有温度的互联网医院，恒安康泰希望可以不断完善服务内容，增强医疗资源入驻和紧密联系，帮助到更多患者轻松看病，将恒安康泰的服务理念和技术力量转化为源源不断社会就医辅助产品，服务大众医疗民生，增强社会价值。非常感谢海淀留创园的支持！”
          </p>
          <p><br></p>
          <p style="text-align: center;"><img src="../../assets/img/mediaCoverage/1632996656.jpg" title="1632996656.jpg"
              alt="IMG_20210924_161124.jpg"></p>
          <p style="text-align: center;">创业者生日会圆满结束，分享体验心得让创业精神持续相传，创业成果惠及更多个人和家庭。</p>
          <p><br></p>
        </div>
      </div>
      <div class="listNewsRight">
        <h2 class="stateNewsRighth2">健康热点资讯</h2>
        <hr class="stateNewsRight_h2_hr" />
        <h3 class="stateNewsRighth3">
          <a href="" title="国务新闻">国务新闻</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[0]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h2 class="stateNewsRighth3">恒安图文</h2>
        <hr class="stateNewsRight_h3_hr" />
        <!-- <a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu1.jpg" alt="" title="恒安康泰医疗" /></a> -->
        <h3 class="stateNewsRighth3">
          <a href="" title="名医出诊">名医出诊</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[1]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h3 class="stateNewsRighth3">
          <a href="" title="健康养生">健康养生</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[2]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h2 class="stateNewsRighth3">恒安图文</h2>
        <hr class="stateNewsRight_h3_hr" />
        <a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu2.jpg" alt="" title="恒安康泰医疗" /></a>
        <h3 class="stateNewsRighth3">
          <a href="" title="健康资讯">健康资讯</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[3]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
      </div>
    </div>
    <vfooter></vfooter>
    <GoTop />
  </div>
</template>
<script>
// import banner from "../../components/banner.vue";
import vheader from "../../components/vheader.vue";
import vfooter from "../../components/vfooter.vue";
import GoTop from "@/components/GoTop.vue";
export default {
  name: 'newscl3',
  data() {
    return {
      navName: '新闻详情',
      navUrl: 'newscl3',
      navId: 5,
      rilist1: [
        [
          { listit: "2020年全国居民健康素养水平达23.15%", lishref: "#" },
          { listit: "癌症治疗新希望！我国首个自主研发...", lishref: "#" },
          { listit: "国家卫健委出台人感染动物源性流感...", lishref: "#" },
          { listit: "国家医保局发文，明确推进门诊费用...", lishref: "#" },
          { listit: "国家卫健委：加强儿童中医药服务 推...", lishref: "#" },
          { listit: "这家西南的医院用高品质医疗服务，建...", lishref: "#" },
          { listit: "“十四五”残疾人康复服务蓝图绘就", lishref: "#" },
          { listit: "《2021医生互联网诊疗行为洞察报告》...", lishref: "#" },
          { listit: "六大方面布局！国办发文推动公立医院...", lishref: "#" },
          { listit: "七大城市用户调研指出：智慧医疗未来...", lishref: "#" },
        ],
        [
          { listit: "中国人民解放军总医院院士合集（部分）", lishref: "#" },
          { listit: "U医公益行启动，让泌尿疾病患者在基层...", lishref: "#" },
          { listit: "第十八期“院士+”西部儿科医师培训班座...", lishref: "#" },
          { listit: "福建首例机器人无腹部切口大肠癌手术在...", lishref: "#" },
          { listit: "多位专家赴湖北十堰参加燃气爆炸紧急医...", lishref: "#" },
          { listit: "张楠：阿尔茨海默病患者护理更需专业指导", lishref: "#" },
          { listit: "第一届智慧医疗与医学人文高峰论坛暨医...", lishref: "#" },
          { listit: "提醒身边长痣的朋友，出现以下五种症状...", lishref: "#" },
          { listit: "“疫”路同行的夫妻档", lishref: "#" },
          { listit: "与北京社团合作，中国西部城市乌兰察布...", lishref: "#" },
        ],
        [
          { listit: "水培蔬菜社交网络走红 专家：较土培更...", lishref: "#" },
          { listit: "山药的做法 山药的功效与作用", lishref: "#" },
          { listit: "发青的鸡蛋黄致癌？ 变绿部分无毒，可...", lishref: "#" },
          { listit: "经常失眠，可能是体内缺什么？3种营养...", lishref: "#" },
          { listit: "你知道健康吃饭的黄金标准吗？", lishref: "#" },
          { listit: "5大习惯能『防癌』，很多人都忽视了", lishref: "#" },
          { listit: "每餐4-6小时的间隔期最符合人体消化需...", lishref: "#" },
          { listit: "晨起一杯水别这么喝，风险太大...教你...", lishref: "#" },
          { listit: "心脏有问题，为什么医生还建议让我多运...", lishref: "#" },
          { listit: "缺铁性贫血，补就完事？补血食物别踩坑", lishref: "#" },
        ],
        [
          { listit: "疾控专家邵一鸣对我国目前疫苗功效给出解读", lishref: "#" },
          {
            listit: "馒头、米饭哪个更容易升血糖？一对比，3餐...",
            lishref: "#",
          },
          { listit: "手抖也能通过外科手术治愈啦", lishref: "#" },
          { listit: "身体血糖高 多喝水好还是少喝水好？看完...", lishref: "#" },
          { listit: "练起来 打太极拳能减“游泳圈”", lishref: "#" },
          { listit: "【科学就医我行动】陈勇皮科问答 | 10.皮...", lishref: "#" },
          { listit: "维生素C有什么功效和副作用？", lishref: "#" },
          {
            listit: "“提高免疫力”，被过度夸大化，免疫平衡比“...",
            lishref: "#",
          },
          { listit: "胃癌如何选择最合适疗方案？CA最新刊文指...", lishref: "#" },
          { listit: "九价HPV疫苗为何“一针难求”", lishref: "#" },
        ],
      ],
    };
  },
  components: {
    // banner,
    vheader,
    vfooter,
    GoTop,
  },
};
</script>
<style lang="less">
@media screen and (min-width:750px) {
  .banner-box {
    .banner-img {
      height: 280Px;
      background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 100%;
    }

    .banner-nav {
      background-color: #f6f8fb;

      .banner-nav-content {
        width: 1200Px;
        margin: auto;

        .el-breadcrumb {
          line-height: 70Px;
        }

        .el-breadcrumb__inner.is-link {
          font-weight: unset;
          color: #333;
          cursor: pointer;

          &:hover {
            color: #093C6C;
            text-decoration: underline;
          }
        }

        span {
          display: flex;
          align-items: center;

          .el-icon-location {
            color: #093C6C;
            font-size: 20Px;
            margin-right: 12Px;
          }

          float: left;
          color:#5a5a5a;
        }
      }
    }
  }
}

@media screen and (max-width:750px) {
  .banner-box {
    .banner-img {
      height: 110px;
      background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 200%;
    }

    .banner-nav {
      background-color: #f6f8fb;

      .banner-nav-content {
        width: 95%;
        margin: auto;

        .el-breadcrumb {
          line-height: 35px;
        }

        .el-breadcrumb__inner.is-link {
          font-weight: unset;
          color: #333;
          cursor: pointer;

          &:hover {
            color: #093C6C;
            text-decoration: underline;
          }
        }

        span {
          display: flex;
          align-items: center;

          .el-icon-location {
            color: #093C6C;
            font-size: 19px;
            margin-right: 2px;
          }

          float: left;
          color:#5a5a5a;
        }
      }
    }
  }
}
</style>